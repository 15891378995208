<template>
  <div class="page">
    <template v-if="list && list.length">
      <div class="page-c3">
        <div
          :key="index"
          @click="goDetail(item)"
          class="page-c3-one"
          v-for="(item,index) in list"
        >
          <div class="top">
            <div class="top-man">
              <div class="top-man-l">
                <img
                  :src="item.publisher.avatarUrl"
                  v-if="item.publisher&&item.publisher.avatarUrl"
                />
                <img src="@/assets/img/user.png"  v-else-if="item.type=='采购产品'"/>
                 <img :src="exhibitionInfo.logo"  v-else/>
              </div>
              <div class="top-man-r">
                <div class="name" v-if="item.publisher&&item.publisher.publisherName">
                  {{item.publisher.publisherName}}
                  <span>{{item.publisher.jobTitle}}</span>
                </div>
                <p v-if="item.publisher&&item.publisher.compName&&item.publisherType==1">{{item.publisher.compName}}</p>
                <p v-else>{{item.publisher?item.publisher.booth:''}}</p>
              </div>
            </div>
            <div class="top-info">
              <span
                class="u-brand s-orange"
                v-if="item.type"
              >{{ item.type }}</span>
              {{item.productName}}
              <div
                class="category-text2"
                v-if="item.content"
              >{{item.content}}</div>
            </div>
            <div
              class="top-img"
            >
              <div class="video_wrap" v-if="item.videos">
                <div class="top_video"  v-for="(video,ind) in item.videos" :key="ind" >
                  <div class="video_mask">
                    <i class="iconfont icon-shipinmianxing"></i>
                  </div>
                  <img :src="video.cover"> 
                </div>
              </div>
              <div class="img_wrap"   v-else-if="item.imgUrls&&item.imgUrls.length">
                <img
                  :key="ix"
                  :src="im"
                  @click.stop="imgClick(item.imgUrls)"
                  v-for="(im,ix) in item.imgUrls"
                />
              </div>
            </div>
            <div class="top-time">
              <p>
                <span>{{item.viewCount}} 阅读</span>
                <span>{{item.commentCount}} 评论</span>
              </p>
              <p>
                <span>{{item.createAt}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      class="no_more mar_bot2"
      v-if="loading"
    >
      <p>加载中...</p>
    </div>
    <div
      class="no_more mar_bot2"
      v-else-if="finished"
    >
      <p>没有更多了</p>
    </div>
    <div
      @click="moreClick"
      class="more mar_bot2"
      v-else-if="list && list.length"
    >
      <p>加载更多</p>
    </div>
    <div
      class="no_more mar_bot2"
      v-else
    >暂无数据！</div>
    <!-- <Supplyqrcode
      :supplyId="supplyId"
      ref="showcode"
    ></Supplyqrcode> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import Supplyqrcode from "@components/Supplyqrcode/index.vue"
import moment from "moment";
export default {
  name: "live-control-start",
  data () {
    return {
      list: [],
      totalCount: 0,
      pageIndex: 1,
      pageSize: 15,
      finished: false,
      loading: false,
      supplyId: ''
    };
  },
  components: {
    // Supplyqrcode
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      userInfo: 'getUser',
      exhibitionInfo:'getExhibitionInfo'
    }),
  },
  created () {
    this.init();
  },
  methods: {
    ...mapActions({
      graphqlPost: 'graphqlPostByZXS',
      linkToDemandDetail:"linkTo/linkToDemandDetail"
    }),
    init () {
      this.getNeed();
    },
    goDetail (item) {
      //   if(!(item.auditState=='PASS'||item.auditState=='Pass')){
      //     return
      //   }
      this.linkToDemandDetail({id:item.id,isFollow:item.isFollow})
    },
    imgClick (imgList) {
      let arr = [];
      imgList.forEach((c) => {
        arr.push(c);
      });
      this.$emit('imgOpen', arr);
    },
    //点击加载更多
    moreClick () {
      if (this.finished) return;
      this.pageIndex += 1;
      this.getNeed()
    },
    async getNeed () {
      this.loading = true;
      let query = `
            query($query:QueryInput!,$exhibitionId: Guid!){
                supplyDemandFollowQuery{
                    queryCustom(query:$query,exhibitionId:$exhibitionId){
                        hasNextPage
                        pageIndex
                        totalCount
                        items{
                            supplyDemand{
                                type
                                content 
                                productName 
                                createAt
                                id
                                userCount
                                commentCount
                                viewCount
                                imgUrls
                                videos
                                isFollow(memberId:"${this.userInfo.inMember.memberId}")
                                publisher{
                                    avatarUrl
                                    compName
                                    publisherName
                                    publishId
                                    jobTitle
                                    booth
                                }
                            }
                        }
                    }
                }
            }
        `;
      let where = {
        "Equal": {
          "n": "memberId",
          "v": this.userInfo.inMember.memberId
        },
      }
      let order = [
        {
          "N": "createAt",
          "v": 0
        }
      ]
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
          exhibitionId: this.exhibitionId
        }
      };
      let data = await this.graphqlPost(opt);
      // console.log(data,'/////')
      let res = JSON.parse(JSON.stringify(data.data.supplyDemandFollowQuery.queryCustom));
      if (res) {
        let arr = [];
        res.items.forEach(e => {
          if (e.supplyDemand.imgUrls) {
            e.supplyDemand.imgUrls = e.supplyDemand.imgUrls.split(';')
          }
          if(e.supplyDemand.videos){
            e.supplyDemand.videos=JSON.parse(e.supplyDemand.videos)
          }
          e.supplyDemand.createAt = moment(e.supplyDemand.createAt).format('YYYY/MM/DD HH:mm:ss')
          arr.push(e.supplyDemand)
        })
        res.items = arr;
        if (this.pageIndex == 1) {
          this.list = res.items
        } else {
          this.list = [...new Set([...this.list, ...res.items])];
        }
      }
      this.loading = false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
      // console.log(this.list);
      // if(data.data.articleQuery.query){
      //     let res = JSON.parse(JSON.stringify(data.data.articleQuery.query));
      //     res.items.map(c=>{
      //     if (c.covers.search(/^\[/) !== -1) {
      //         c.covers = JSON.parse(c.covers);
      //     } else {
      //         c.covers = [c.covers];
      //     }
      //     })
      //     if(this.hotNewList.length){
      //     this.hotNewList = [...this.hotNewList,...res.items];
      //     } else {
      //     this.hotNewList = res.items;
      //     }
      //     this.ToTal = res.totalCount;
      // }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.page {
  &-c3 {
    display: flex;
    flex-wrap: wrap;
    padding: 4px 20px 20px 20px;
    &-one {
      width: 309px;
      margin: 0 20px 20px 0;
    }
    &-one:nth-child(3n + 0) {
      margin-right: 0;
    }
    .top {
      background-color: #f7f7f7;
      padding: 10px;
      &-man {
        display: flex;
        margin-bottom: 8px;
        &-l {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          margin-right: 10px;
          img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        &-r {
          .name {
            span {
              font-size: 12px;
              color: #666;
              margin-left: 8px;
            }
          }
          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 190px;
            color: #666;
            font-size: 12px;
            margin-top: 6px;
          }
        }
      }
      &-info {
        text-align: justify;
        height: 70px;
        line-height: 1.8;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        span {
          margin-right: 10px;
          line-height: 1.5;
        }
      }
      &-img {
        height: 50px;
        margin: 10px 0;
        .video_wrap{
          display: flex;
          .top_video{
            margin-right: 10px;
            width: 50px;
            height: 50px;
            display: block;
            position: relative;
            .video_mask{
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: rgba($color: #000000, $alpha: 0.2);
              display: flex;
              align-items: center;
              justify-content: center;
              i{
                color: #fff;
              }
            }
            img{
              width: 100%;
              height: 100%;
              display: block;
            }
          }
        }
        .img_wrap{
          display: flex;
          img {
            margin-right: 10px;
            width: 50px;
            height: 50px;
            display: block;
          }
        }
      }
      &-time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          color: #999;
          font-size: 12px;
          span {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.category-text2 {
  padding-left: 3px;
  font-size: 12px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  span {
    color: #666;
  }
}
</style>
