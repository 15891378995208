<template>
  <div class="page">
    <template v-if="list && list.length">
      <div class="page-c3">
        <div
          @click="goDetail(item)"
          :key="index"
          class="page-c3-one"
          v-for="(item, index) in list"
        >
          <div class="top">
            <div class="top-info">
              <span
                class="u-brand s-orange"
                v-if="item.type"
              >{{ item.type }}</span>
              {{item.productName}}
              <div
                class="category-text2"
                v-if="item.content"
              >{{item.content}}</div>
            </div>
            <div
              class="top-img"
            >
              <div class="video_wrap" v-if="item.videos">
                <div class="top_video"  v-for="(video,ind) in item.videos" :key="ind" >
                  <div class="video_mask">
                    <i class="iconfont icon-shipinmianxing"></i>
                  </div>
                  <img :src="video.cover"> 
                </div>
              </div>
              <div class="img_wrap" @click.stop="imgClick(item.imgUrls)"  v-else-if="item.imgUrls&&item.imgUrls.length">
                <img
                  :key="ix"
                  :src="im"
                  v-for="(im,ix) in item.imgUrls"
                />
              </div>
            </div>
            <div class="top-tool">
              <div
                class="top-time"
                v-if="item.auditState == 'PASS'"
              >
                <p>
                  <span>{{ item.viewCount }} 阅读</span>
                  <span>{{ item.commentCount }} 评论</span>
                </p>
                <p>
                  <span>{{ item.createAt }}</span>
                </p>
              </div>
              <div
                class="top-time"
                v-else
              >
                <p>
                  <span
                    class="state-no"
                    v-if="item.auditState == 'NoPass'"
                  >未通过</span>
                  <span
                    class="state-ing"
                    v-else
                  >审核中</span>
                </p>
                <p v-if="item.auditState == 'NoPass'">
                  <Poptip
                    @on-ok="delItem(item)"
                    confirm
                    title="确认删除吗?"
                  >
                    <span class="del">删除</span>
                  </Poptip>
                  <span
                    @click.stop="edit(item)"
                    class="edit"
                  >重新编辑</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      class="no_more mar_bot2"
      v-if="loading"
    >
      <p>加载中...</p>
    </div>
    <div
      class="no_more mar_bot2"
      v-else-if="finished"
    >
      <p>没有更多了</p>
    </div>
    <div
      @click="moreClick"
      class="more mar_bot2"
      v-else-if="list && list.length"
    >
      <p>加载更多</p>
    </div>
    <div
      class="no_more mar_bot2"
      v-else
    >暂无数据！</div>
    <needsModel
      @finish="init()"
      ref="needsModel"
    ></needsModel>
    <!-- <Supplyqrcode
      :supplyId="supplyId"
      ref="showcode"
    ></Supplyqrcode> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import needsModel from "@components/needsModel";
// import Supplyqrcode from "@components/Supplyqrcode/index.vue"
import moment from "moment";
export default {
  name: "live-control-start",
  data () {
    return {
      list: [],
      totalCount: 0,
      pageIndex: 1,
      pageSize: 15,
      finished: false,
      loading: false,
      supplyId: ''
    };
  },
  components: {
    needsModel,
    // Supplyqrcode
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      userInfo: "getUser",
    }),
  },
  created () {
    this.init();
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      delSupply: "news/delSupply",
      linkToDemandDetail:"linkTo/linkToDemandDetail"
    }),
    init () {
      this.getNeed();
    },
    goDetail (item) {
      if (!(item.auditState == 'PASS' || item.auditState == 'Pass')) {
        return
      }
      this.linkToDemandDetail({id:item.id,isFollow:false})
    },
    imgClick (imgList) {
      let arr = [];
      imgList.forEach((c) => {
        arr.push(c);
      });
      this.$emit('imgOpen', arr);
    },

    async getNeed () {
      this.loading = true;
      let query = `
        query($query:QueryInput!){
          supplyDemandQuery{
            query(query:$query){
              hasNextPage
              pageIndex
              totalCount
              items {
                auditState
                commentCount
                exhibitionId
                content 
                productName 
                createAt
                id
                userCount
                memberId
                viewCount
                imgUrls
                videos
                type
              }
            }
          }
        }
      `;
      let where = {
        Equal: {
          n: "memberId",
          v: this.userInfo.inMember.memberId,
        },
        EqualB: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
      };
      let order = [
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.supplyDemandQuery.query));
      if (res) {
        res.items.map((e) => {
          if (e.imgUrls) {
            e.imgUrls = e.imgUrls.split(";");
          }
          if(e.videos){
            e.videos=JSON.parse(e.videos)
          }
          e.createAt = moment(e.createAt).format("YYYY/MM/DD HH:mm:ss");
        });
        if (this.pageIndex == 1) {
          this.list = res.items;
        } else {
          this.list = [...new Set([...this.list, ...res.items])];
        }
      }
      this.loading = false;
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    //点击加载更多
    moreClick () {
      if (this.finished) return;
      this.pageIndex += 1;
      this.getNeed();
    },
    //删除供需
    async delItem (item) {
      let res = await this.delSupply(item.id);
      if (res.result) {
        this.list = this.list.filter((c) => c.id != item.id);
        this.$Message.success("删除成功");
      } else {
        this.$Message.error(res.message);
      }
    },
    // 编辑供需
    edit (item) {
      this.$refs.needsModel.open(item);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.page {
  &-c3 {
    display: flex;
    flex-wrap: wrap;
    padding: 4px 20px 20px 20px;
    &-one {
      width: 309px;
      margin: 0 20px 20px 0;
    }
    &-one:nth-child(3n + 0) {
      margin-right: 0;
    }
    .top {
      background-color: #f7f7f7;
      padding: 10px;
      &-info {
        text-align: justify;
        height: 70px;
        line-height: 1.8;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        span {
          margin-right: 10px;
          line-height: 1.5;
        }
      }
      &-img {
        height: 50px;
        margin: 10px 0;
        .video_wrap{
          display: flex;
          .top_video{
            margin-right: 10px;
            width: 50px;
            height: 50px;
            display: block;
            position: relative;
            .video_mask{
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background: rgba($color: #000000, $alpha: 0.2);
              display: flex;
              align-items: center;
              justify-content: center;
              i{
                color: #fff;
              }
            }
            img{
              width: 100%;
              height: 100%;
              display: block;
            }
          }
        }
        .img_wrap{
          display: flex;
          img {
            margin-right: 10px;
            width: 50px;
            height: 50px;
            display: block;
          }
        }
      }
      &-tool {
        height: 24px;
      }
      &-time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 24px;
        p {
          color: #999;
          font-size: 12px;
          display: flex;
          align-items: center;
          span {
            margin-right: 10px;
          }
          .state-ing {
            color: #ff5e00;
          }
          .state-no {
            color: #666;
          }
          .del {
            color: #ff5e00;
            cursor: pointer;
          }
          .edit {
            width: 64px;
            height: 24px;
            @include background_color(#1890ff);
            border-radius: 3px;
            color: #fff;
            text-align: center;
            display: inline-block;
            line-height: 24px;
            margin-right: 0;
            cursor: pointer;
          }
        }
      }
    }
    .live-tool {
      margin-top: 10px;
      display: flex;
      align-items: center;
      width: 310px;
      height: 36px;
      background-color: #f7f7f7;
      border-radius: 3px;
      border: solid 1px #ddd;
      &-item {
        width: 50%;
        text-align: center;
        border-right: 1px solid #ddd;
        cursor: pointer;
      }
      &-item:last-child {
        border: none;
      }
    }
  }
}
.category-text2 {
  padding-left: 3px;
  font-size: 12px;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  span {
    color: #666;
  }
}
</style>
